@font-face {
    font-family: "ALSHauss-Regular";  
    src: url("../fonts/ALSHauss-Regular.woff") format("truetype"); 
}
@font-face {
    font-family: "ALSHauss-Medium";  
    src: url("../fonts/ALSHauss-Medium.woff") format("truetype"); 
}
@font-face {
    font-family: "ALSHauss-Book";  
    src: url("../fonts/ALSHauss-Book.woff") format("truetype"); 
}
@font-face {
font-family: "ALSHauss-Bold";  
src: url("../fonts/ALSHauss-Bold.woff") format("truetype"); 
}

body {
    font-family: "ALSHauss-Regular";
}
 
 .brt-text-blue{
    color:#2267B4;
}

.brt-bg-blue{
    background-color:#2267B4;
}
.brt-bg-grey{
    background-color:#e0efff;
}
.brt-bg-white{
    background-color:#ffffff;
}
.brt-text-regular{
    font-family: "ALSHauss-Regular";  
}
.brt-text-medium{
    font-family: "ALSHauss-Medium";  
}
.brt-text-bold{
    font-family: "ALSHauss-Bold";  
}
.brt-text-book{
    font-family: "ALSHauss-Book";  
}
.hide-scrollbar {
    scrollbar-width: none;
}
.brt-color-blue{
    color:#2267B4;  
}
.hover\:brt-color-blue:hover{color:#2267B4;}
.hover\:brt-bg-blue:hover{background-color:#1d589b;}
.hover\:brt-color-1:hover{color:#51a3ff;}
.hover\:brt-color-2:hover{color:#2776cf;}
.hover\:brt-weight-600:hover{font-weight: 600;}
.hover\:brt-weight-500:hover{font-weight: 500;}
.brt-color-grey{
    color:#676971;  
}
.brt-color-white{
    color:#ffffff;  
}



.brt-textsize-24{
    font-size: 24px;  
}
.brt-textsize-20{
    font-size: 20px;  
}
.brt-textsize-12{
    font-size: 14px;  
}
.brt-border-1px-grey {
    border-color: #d1d1d1;
    border-width: 1px;
}
.hover\:brt-border-color-primary:hover{border-color:#51a3ff;}
.brt-px-5{
    padding-left: 5px;
    padding-right: 5px;
}
.brt-py-3{
    padding-top: 3px;
    padding-bottom: 3px;
}
#brt_footer .rounded-t-2xl {
    border-top-left-radius: 1.5rem;
    border-top-right-radius: 1.5rem
}
#brt_footer .mb-6 {
    margin-bottom: 1.5rem
}
#brt_footer .container {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding-right: 1rem;
    padding-left: 1rem
}
#brt_footer .text-helper {
    font-size: .75rem;
    line-height: 1.5
}
#brt_footer .text-gray-2 {
    --tw-text-opacity: 1;
    color: rgb(139 142 153 / var(--tw-text-opacity))
}
#brt_footer .mb-3 {
    margin-bottom: .75rem
}
#brt_footer .pt-6 {
    padding-top: 1.5rem
}
#brt_footer .title-text-1 {
    font-size: 1.25rem;
    line-height: 1.3;
    letter-spacing: -.5px
}
#brt_footer .font-medium {
    font-weight: 500
}
@media (min-width: 100%) {
    #brt_footer .container {
        max-width:100%
    }
}

@media (min-width: 1200px) {
    #brt_footer .container {
        max-width:1200px
    }
}
#brt_footer .pb-12 {
    padding-bottom: 3rem
}
#brt_footer .pt-5 {
    padding-top: 1.25rem
}
#brt_footer .flex {
    display: flex
}
#brt_footer .items-center {
    align-items: center
}
#brt_footer .pl-3 {
    padding-left: .75rem
}
#brt_footer .pt-3 {
    padding-top: .75rem
}
#brt_footer .ml-1 {
    margin-left: .25rem
}
#brt_footer .mr-1 {
    margin-right: .25rem
}
#brt_footer .transition {
    transition-property: color,background-color,border-color,text-decoration-color,fill,stroke,opacity,box-shadow,transform,filter,-webkit-backdrop-filter;
    transition-property: color,background-color,border-color,text-decoration-color,fill,stroke,opacity,box-shadow,transform,filter,backdrop-filter;
    transition-property: color,background-color,border-color,text-decoration-color,fill,stroke,opacity,box-shadow,transform,filter,backdrop-filter,-webkit-backdrop-filter;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
    transition-duration: .15s
}
#brt_footer .justify-between {
    justify-content: space-between
}
#brt_footer .pb-10 {
    padding-bottom: 2.5rem
}
@media (min-width: 640px) {
    #brt_footer .sm\:absolute {
        position:absolute
    }

    #brt_footer .sm\:relative {
        position: relative
    }

    #brt_footer .sm\:bottom-2 {
        bottom: .5rem
    }

    #brt_footer .sm\:right-2 {
        right: .5rem
    }

    #brt_footer .sm\:top-4 {
        top: 1rem
    }

    #brt_footer .sm\:-order-1 {
        order: -1
    }

    #brt_footer .sm\:order-first {
        order: -9999
    }

    #brt_footer .sm\:order-last {
        order: 9999
    }

    #brt_footer .sm\:-mx-2 {
        margin-left: -.5rem;
        margin-right: -.5rem
    }

    #brt_footer .sm\:-mx-4 {
        margin-left: -1rem;
        margin-right: -1rem
    }

    #brt_footer .sm\:mx-0 {
        margin-left: 0;
        margin-right: 0
    }

    #brt_footer .sm\:mx-1 {
        margin-left: .25rem;
        margin-right: .25rem
    }

    #brt_footer .sm\:\!mb-2 {
        margin-bottom: .5rem!important
    }

    #brt_footer .sm\:-ml-3 {
        margin-left: -.75rem
    }

    #brt_footer .sm\:mb-0 {
        margin-bottom: 0
    }

    #brt_footer .sm\:mb-10 {
        margin-bottom: 2.5rem
    }

    #brt_footer .sm\:mb-14 {
        margin-bottom: 3.5rem
    }

    #brt_footer .sm\:mb-2 {
        margin-bottom: .5rem
    }

    #brt_footer .sm\:mb-3 {
        margin-bottom: .75rem
    }

    #brt_footer .sm\:mb-4 {
        margin-bottom: 1rem
    }

    #brt_footer .sm\:mb-5 {
        margin-bottom: 1.25rem
    }

    #brt_footer .sm\:mb-6 {
        margin-bottom: 1.5rem
    }

    #brt_footer .sm\:mb-7 {
        margin-bottom: 1.75rem
    }

    #brt_footer .sm\:ml-0 {
        margin-left: 0
    }

    #brt_footer .sm\:mr-3 {
        margin-right: .75rem
    }

    #brt_footer .sm\:mt-2 {
        margin-top: .5rem
    }

    #brt_footer .sm\:mt-4 {
        margin-top: 1rem
    }

    #brt_footer .sm\:mt-6 {
        margin-top: 1.5rem
    }

    #brt_footer .sm\:mt-9 {
        margin-top: 2.25rem
    }

    #brt_footer .sm\:\!block {
        display: block!important
    }

    #brt_footer .sm\:block {
        display: block
    }

    #brt_footer .sm\:inline-block {
        display: inline-block
    }

    #brt_footer .sm\:flex {
        display: flex
    }

    #brt_footer .sm\:inline-flex {
        display: inline-flex
    }

    #brt_footer .sm\:hidden {
        display: none
    }

    #brt_footer .sm\:h-14 {
        height: 3.5rem
    }

    #brt_footer .sm\:h-8 {
        height: 2rem
    }

    #brt_footer .sm\:h-\[730px\] {
        height: 730px
    }

    #brt_footer .sm\:h-full {
        height: 100%
    }

    #brt_footer .sm\:min-h-\[200px\] {
        min-height: 200px
    }

    #brt_footer .sm\:w-1\/2 {
        width: 50%
    }

    #brt_footer .sm\:w-8 {
        width: 2rem
    }

    #brt_footer .sm\:w-full {
        width: 100%
    }

    #brt_footer .sm\:max-w-\[441px\] {
        max-width: 441px
    }

    #brt_footer .sm\:rotate-90 {
        --tw-rotate: 90deg;
        transform: translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
    }

    #brt_footer .sm\:transform {
        transform: translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
    }

    #brt_footer .sm\:grid-cols-1 {
        grid-template-columns: repeat(1,minmax(0,1fr))
    }

    #brt_footer .sm\:flex-row {
        flex-direction: row
    }

    #brt_footer .sm\:flex-col {
        flex-direction: column
    }

    #brt_footer .sm\:flex-wrap {
        flex-wrap: wrap
    }

    #brt_footer .sm\:items-center {
        align-items: center
    }
    #brt_footer .md\:flex-wrap {
        flex-wrap: wrap
    }
    #brt_footer .lg\:flex-wrap {
        flex-wrap: wrap
    }

    #brt_footer .sm\:justify-center {
        justify-content: center
    }

    #brt_footer .sm\:justify-between {
        justify-content: space-between
    }

    #brt_footer .sm\:space-x-0>:not([hidden])~:not([hidden]) {
        --tw-space-x-reverse: 0;
        margin-right: calc(0px * var(--tw-space-x-reverse));
        margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse)))
    }
    #brt_footer .lg\:space-x-0>:not([hidden])~:not([hidden]) {
        --tw-space-x-reverse: 0;
        margin-right: calc(0px * var(--tw-space-x-reverse));
        margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse)))
    }
    #brt_footer .\<lg\:space-x-0>:not([hidden])~:not([hidden]) {
        --tw-space-x-reverse: 0;
        margin-right: calc(0px * var(--tw-space-x-reverse));
        margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse)))
    }

    #brt_footer .sm\:space-x-2>:not([hidden])~:not([hidden]) {
        --tw-space-x-reverse: 0;
        margin-right: calc(.5rem * var(--tw-space-x-reverse));
        margin-left: calc(.5rem * calc(1 - var(--tw-space-x-reverse)))
    }

    #brt_footer .sm\:space-x-4>:not([hidden])~:not([hidden]) {
        --tw-space-x-reverse: 0;
        margin-right: calc(1rem * var(--tw-space-x-reverse));
        margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)))
    }
    #brt_footer .space-x-4>:not([hidden])~:not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1rem * var(--tw-space-x-reverse));
    margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)))
}

    #brt_footer .sm\:rounded-base {
        border-radius: 1rem
    }

    #brt_footer .sm\:rounded-l-2xl {
        border-top-left-radius: 1.5rem;
        border-bottom-left-radius: 1.5rem
    }

    #brt_footer .sm\:border-0 {
        border-width: 0px
    }

    #brt_footer .sm\:border-b {
        border-bottom-width: 1px
    }

    #brt_footer .sm\:border-r-0 {
        border-right-width: 0px
    }

    #brt_footer .sm\:border-t {
        border-top-width: 1px
    }

    #brt_footer .sm\:border-gray-mr-5 {
        --tw-border-opacity: 1;
        border-color: rgb(206 211 219 / var(--tw-border-opacity))
    }

    #brt_footer .sm\:border-stroke {
        --tw-border-opacity: 1;
        border-color: rgb(209 213 229 / var(--tw-border-opacity))
    }

    #brt_footer .sm\:bg-white {
        --tw-bg-opacity: 1;
        background-color: rgb(255 255 255 / var(--tw-bg-opacity))
    }

    #brt_footer .sm\:p-0 {
        padding: 0
    }

    #brt_footer .sm\:p-4 {
        padding: 1rem
    }

    #brt_footer .sm\:p-6 {
        padding: 1.5rem
    }

    #brt_footer .sm\:px-0 {
        padding-left: 0;
        padding-right: 0
    }

    #brt_footer .sm\:px-1 {
        padding-left: .25rem;
        padding-right: .25rem
    }

    #brt_footer .sm\:px-2 {
        padding-left: .5rem;
        padding-right: .5rem
    }

    #brt_footer .sm\:px-4 {
        padding-left: 1rem;
        padding-right: 1rem
    }

    #brt_footer .sm\:px-5 {
        padding-left: 1.25rem;
        padding-right: 1.25rem
    }

    #brt_footer .sm\:py-0 {
        padding-top: 0;
        padding-bottom: 0
    }

    #brt_footer .sm\:py-2 {
        padding-top: .5rem;
        padding-bottom: .5rem
    }

    #brt_footer .sm\:py-4 {
        padding-top: 1rem;
        padding-bottom: 1rem
    }

    #brt_footer .sm\:py-6 {
        padding-top: 1.5rem;
        padding-bottom: 1.5rem
    }

    #brt_footer .sm\:pb-0 {
        padding-bottom: 0
    }

    #brt_footer .sm\:pb-2 {
        padding-bottom: .5rem
    }

    #brt_footer .sm\:pb-4 {
        padding-bottom: 1rem
    }

    #brt_footer .sm\:pb-6 {
        padding-bottom: 1.5rem
    }

    #brt_footer .sm\:pb-8 {
        padding-bottom: 2rem
    }

    #brt_footer .sm\:pl-3 {
        padding-left: .75rem
    }

    #brt_footer .sm\:pt-0 {
        padding-top: 0
    }

    #brt_footer .sm\:pt-1 {
        padding-top: .25rem
    }

    #brt_footer .sm\:pt-2 {
        padding-top: .5rem
    }

    #brt_footer .sm\:pt-4 {
        padding-top: 1rem
    }

    #brt_footer .sm\:pt-6 {
        padding-top: 1.5rem
    }

    #brt_footer .sm\:pt-\[136px\] {
        padding-top: 136px
    }

    #brt_footer .sm\:text-center {
        text-align: center
    }

    #brt_footer .sm\:font-medium {
        font-weight: 500
    }

    #brt_footer .sm\:font-semibold {
        font-weight: 600
    }

    #brt_footer .sm\:text-dark {
        --tw-text-opacity: 1;
        color: rgb(28 28 30 / var(--tw-text-opacity))
    }

    #brt_footer .sm\:text-white {
        --tw-text-opacity: 1;
        color: rgb(255 255 255 / var(--tw-text-opacity))
    }

    #brt_footer .sm\:last\:pr-4:last-child {
        padding-right: 1rem
    }

    :is([data-mode=dark] #brt_footer .sm\:dark\:bg-gray-3) {
        --tw-bg-opacity: 1;
        background-color: rgb(60 61 63 / var(--tw-bg-opacity))
    }

    :is([data-mode=dark] #brt_footer .sm\:dark\:text-white) {
        --tw-text-opacity: 1;
        color: rgb(255 255 255 / var(--tw-text-opacity))
    }
}
@media (max-width: 639px) {
    #brt_footer .\<sm\:absolute {
        position:absolute
    }

    #brt_footer .\<sm\:bottom-2 {
        bottom: .5rem
    }

    #brt_footer .\<sm\:right-2 {
        right: .5rem
    }

    #brt_footer .\<sm\:top-4 {
        top: 1rem
    }

    #brt_footer .\<sm\:-order-1 {
        order: -1
    }

    #brt_footer .\<sm\:order-first {
        order: -9999
    }

    #brt_footer .\<sm\:order-last {
        order: 9999
    }

    #brt_footer .\<sm\:-mx-2 {
        margin-left: -.5rem;
        margin-right: -.5rem
    }

    #brt_footer .\<sm\:-mx-4 {
        margin-left: -1rem;
        margin-right: -1rem
    }

    #brt_footer .\<sm\:mx-0 {
        margin-left: 0;
        margin-right: 0
    }

    #brt_footer .\<sm\:mx-1\.5 {
        margin-left: .375rem;
        margin-right: .375rem
    }

    #brt_footer .\<sm\:\!mb-2 {
        margin-bottom: .5rem!important
    }

    #brt_footer .\<sm\:-ml-3 {
        margin-left: -.75rem
    }

    #brt_footer .\<sm\:mb-0 {
        margin-bottom: 0
    }

    #brt_footer .\<sm\:mb-10 {
        margin-bottom: 2.5rem
    }

    #brt_footer .\<sm\:mb-14 {
        margin-bottom: 3.5rem
    }

    #brt_footer .\<sm\:mb-2 {
        margin-bottom: .5rem
    }

    #brt_footer .\<sm\:mb-3 {
        margin-bottom: .75rem
    }

    #brt_footer .\<sm\:mb-4 {
        margin-bottom: 1rem
    }

    #brt_footer .\<sm\:mb-5 {
        margin-bottom: 1.25rem
    }

    #brt_footer .\<sm\:mb-6 {
        margin-bottom: 1.5rem
    }

    #brt_footer .\<sm\:mb-7 {
        margin-bottom: 1.75rem
    }

    #brt_footer .\<sm\:ml-0 {
        margin-left: 0
    }

    #brt_footer .\<sm\:mr-3 {
        margin-right: .75rem
    }

    #brt_footer .\<sm\:mt-2 {
        margin-top: .5rem
    }

    #brt_footer .\<sm\:mt-4 {
        margin-top: 1rem
    }

    #brt_footer .\<sm\:mt-6 {
        margin-top: 1.5rem
    }

    #brt_footer .\<sm\:mt-9 {
        margin-top: 2.25rem
    }

    #brt_footer .\<sm\:\!block {
        display: block!important
    }

    #brt_footer .\<sm\:block {
        display: block
    }

    #brt_footer .\<sm\:inline-block {
        display: inline-block
    }

    #brt_footer .\<sm\:flex {
        display: flex
    }

    #brt_footer .\<sm\:inline-flex {
        display: inline-flex
    }

    #brt_footer .\<sm\:hidden {
        display: none
    }

    #brt_footer .\<sm\:h-8 {
        height: 2rem
    }

    #brt_footer .\<sm\:h-\[340px\] {
        height: 340px
    }

    #brt_footer .\<sm\:h-\[calc\(100vh-92px\)\] {
        height: calc(100vh - 92px)
    }

    #brt_footer .\<sm\:h-full {
        height: 100%
    }

    #brt_footer .\<sm\:max-h-\[32px\] {
        max-height: 32px
    }

    #brt_footer .\<sm\:min-h-\[379px\] {
        min-height: 379px
    }

    #brt_footer .\<sm\:min-h-\[initial\] {
        min-height: initial
    }

    #brt_footer .\<sm\:w-1\/2 {
        width: 50%
    }

    #brt_footer .\<sm\:w-8 {
        width: 2rem
    }

    #brt_footer .\<sm\:w-\[70\%\] {
        width: 70%
    }

    #brt_footer .\<sm\:w-full {
        width: 100%
    }

    #brt_footer .\<sm\:min-w-\[70\%\] {
        min-width: 70%
    }

    #brt_footer .\<sm\:max-w-\[224px\] {
        max-width: 224px
    }

    #brt_footer .\<sm\:basis-\[300px\] {
        flex-basis: 300px
    }

    #brt_footer .\<sm\:rotate-90 {
        --tw-rotate: 90deg;
        transform: translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
    }

    #brt_footer .\<sm\:transform {
        transform: translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
    }

    #brt_footer .\<sm\:grid-cols-1 {
        grid-template-columns: repeat(1,minmax(0,1fr))
    }

    #brt_footer .\<sm\:flex-row {
        flex-direction: row
    }

    #brt_footer .\<sm\:flex-col {
        flex-direction: column
    }

    #brt_footer .\<sm\:flex-wrap {
        flex-wrap: wrap
    }

    #brt_footer .\<sm\:justify-center {
        justify-content: center
    }

    #brt_footer .\<sm\:justify-between {
        justify-content: space-between
    }

    #brt_footer .\<sm\:space-x-0>:not([hidden])~:not([hidden]) {
        --tw-space-x-reverse: 0;
        margin-right: calc(0px * var(--tw-space-x-reverse));
        margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse)))
    }

    #brt_footer .\<sm\:space-x-2>:not([hidden])~:not([hidden]) {
        --tw-space-x-reverse: 0;
        margin-right: calc(.5rem * var(--tw-space-x-reverse));
        margin-left: calc(.5rem * calc(1 - var(--tw-space-x-reverse)))
    }

    #brt_footer .\<sm\:space-x-4>:not([hidden])~:not([hidden]) {
        --tw-space-x-reverse: 0;
        margin-right: calc(1rem * var(--tw-space-x-reverse));
        margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)))
    }

    #brt_footer .\<sm\:rounded-base {
        border-radius: 1rem
    }

    #brt_footer .\<sm\:border-0 {
        border-width: 0px
    }

    #brt_footer .\<sm\:border-b {
        border-bottom-width: 1px
    }

    #brt_footer .\<sm\:border-r-0 {
        border-right-width: 0px
    }

    #brt_footer .\<sm\:border-t {
        border-top-width: 1px
    }

    #brt_footer .\<sm\:border-gray-mr-5 {
        --tw-border-opacity: 1;
        border-color: rgb(206 211 219 / var(--tw-border-opacity))
    }

    #brt_footer .\<sm\:border-stroke {
        --tw-border-opacity: 1;
        border-color: rgb(209 213 229 / var(--tw-border-opacity))
    }

    #brt_footer .\<sm\:bg-white {
        --tw-bg-opacity: 1;
        background-color: rgb(255 255 255 / var(--tw-bg-opacity))
    }

    #brt_footer .\<sm\:p-0 {
        padding: 0
    }

    #brt_footer .\<sm\:p-4 {
        padding: 1rem
    }

    #brt_footer .\<sm\:p-6 {
        padding: 1.5rem
    }

    #brt_footer .\<sm\:px-0 {
        padding-left: 0;
        padding-right: 0
    }

    #brt_footer .\<sm\:px-1\.5 {
        padding-left: .375rem;
        padding-right: .375rem
    }

    #brt_footer .\<sm\:px-2 {
        padding-left: .5rem;
        padding-right: .5rem
    }

    #brt_footer .\<sm\:px-4 {
        padding-left: 1rem;
        padding-right: 1rem
    }

    #brt_footer .\<sm\:px-5 {
        padding-left: 1.25rem;
        padding-right: 1.25rem
    }

    #brt_footer .\<sm\:py-0 {
        padding-top: 0;
        padding-bottom: 0
    }

    #brt_footer .\<sm\:py-2 {
        padding-top: .5rem;
        padding-bottom: .5rem
    }

    #brt_footer .\<sm\:py-4 {
        padding-top: 1rem;
        padding-bottom: 1rem
    }

    #brt_footer .\<sm\:py-6 {
        padding-top: 1.5rem;
        padding-bottom: 1.5rem
    }

    #brt_footer .\<sm\:pb-0 {
        padding-bottom: 0
    }

    #brt_footer .\<sm\:pb-2 {
        padding-bottom: .5rem
    }

    #brt_footer .\<sm\:pb-4 {
        padding-bottom: 1rem
    }

    #brt_footer .\<sm\:pb-6 {
        padding-bottom: 1.5rem
    }

    #brt_footer .\<sm\:pb-8 {
        padding-bottom: 2rem
    }

    #brt_footer .\<sm\:pb-\[43\%\] {
        padding-bottom: 43%
    }

    #brt_footer .\<sm\:pb-\[57\%\] {
        padding-bottom: 57%
    }

    #brt_footer .\<sm\:pl-3 {
        padding-left: .75rem
    }

    #brt_footer .\<sm\:pt-0 {
        padding-top: 0
    }

    #brt_footer .\<sm\:pt-1 {
        padding-top: .25rem
    }

    #brt_footer .\<sm\:pt-2 {
        padding-top: .5rem
    }

    #brt_footer .\<sm\:pt-4 {
        padding-top: 1rem
    }

    #brt_footer .\<sm\:pt-6 {
        padding-top: 1.5rem
    }

    #brt_footer .\<sm\:pt-\[3\.25rem\] {
        padding-top: 3.25rem
    }

    #brt_footer .\<sm\:text-center {
        text-align: center
    }

    #brt_footer .\<sm\:font-medium {
        font-weight: 500
    }

    #brt_footer .\<sm\:font-semibold {
        font-weight: 600
    }

    #brt_footer .\<sm\:text-dark {
        --tw-text-opacity: 1;
        color: rgb(28 28 30 / var(--tw-text-opacity))
    }

    #brt_footer .\<sm\:text-white {
        --tw-text-opacity: 1;
        color: rgb(255 255 255 / var(--tw-text-opacity))
    }

    #brt_footer .\<sm\:last\:pr-4:last-child {
        padding-right: 1rem
    }

    :is([data-mode=dark] #brt_footer .\<sm\:dark\:bg-\[\#1C1C1C\]) {
        --tw-bg-opacity: 1;
        background-color: rgb(28 28 28 / var(--tw-bg-opacity))
    }

    :is([data-mode=dark] #brt_footer .\<sm\:dark\:bg-gray-3) {
        --tw-bg-opacity: 1;
        background-color: rgb(60 61 63 / var(--tw-bg-opacity))
    }

    :is([data-mode=dark] #brt_footer .\<sm\:dark\:text-white) {
        --tw-text-opacity: 1;
        color: rgb(255 255 255 / var(--tw-text-opacity))
    }
}
#brt_footer .space-x-12>:not([hidden])~:not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(3rem * var(--tw-space-x-reverse));
    margin-left: calc(3rem * calc(1 - var(--tw-space-x-reverse)))
}
#brt_footer .self-baseline {
    align-self: baseline
}
#brt_footer .inline-block {
    display: inline-block
}
#brt_footer .heading-5 {
    font-size: 1.5rem;
    line-height: 1.3;
    font-weight: 600
}

@media (max-width: 639px) {
    #brt_footer .\!heading-5 {
        font-size:1rem!important;
        letter-spacing: -.2px!important;
        font-weight: 600!important
    }

    #brt_footer .heading-5 {
        font-size: 1rem;
        letter-spacing: -.2px;
        font-weight: 600
    }
}

#brt_footer .text-primary {
    --tw-text-opacity: 1;
    color: rgb(39 174 96 / var(--tw-text-opacity))
}
#brt_footer .mb-1 {
    margin-bottom: .25rem
}
#brt_footer .text-gray-1 {
    --tw-text-opacity: 1;
    color: rgb(103 105 113 / var(--tw-text-opacity))
}
#brt_footer .text-small {
    font-size: .875rem;
    line-height: 1.5
}

@media (max-width: 639px) {
    #brt_footer .\!text-small {
        line-height:1.4!important
    }

    #brt_footer .text-small {
        line-height: 1.4
    }
}
#brt_footer .space-x-6>:not([hidden])~:not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.5rem * var(--tw-space-x-reverse));
    margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse)))
}
#brt_footer .pt-12 {
    padding-top: 3rem
}
#brt_footer .pb-20 {
    padding-bottom: 5rem
}
#brt_footer .border-y {
    border-top-width: 1px;
    border-bottom-width: 1px
}
#brt_footer .border-stroke {
    --tw-border-opacity: 1;
    border-color: rgb(209 213 229 / var(--tw-border-opacity))
}
#brt_footer .w-full {
    width: 100%
}
#brt_footer .duration-300 {
    transition-duration: .3s
}
#brt_footer .rotate-180 {
    --tw-rotate: 180deg;
    transform: translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}
#brt_footer .space-y-4>:not([hidden])~:not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1rem * var(--tw-space-y-reverse))
}
@media (min-width: 1024px) {
    #brt_footer .lg\:mb-12 {
        margin-bottom:3rem
    }

    #brt_footer .lg\:ml-0 {
        margin-left: 0
    }

    #brt_footer .lg\:w-1\/2 {
        width: 50%
    }

    #brt_footer .lg\:w-5\/12 {
        width: 41.666667%
    }

    #brt_footer .lg\:flex-wrap {
        flex-wrap: wrap
    }

    #brt_footer .lg\:space-x-0>:not([hidden])~:not([hidden]) {
        --tw-space-x-reverse: 0;
        margin-right: calc(0px * var(--tw-space-x-reverse));
        margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse)))
    }
}
@media (max-width: 1023px) {
    #brt_footer .\<lg\:mb-12 {
        margin-bottom:3rem
    }

    #brt_footer .\<lg\:ml-0 {
        margin-left: 0
    }

    #brt_footer .\<lg\:w-1\/2 {
        width: 50%
    }

    #brt_footer .\<lg\:w-5\/12 {
        width: 41.666667%
    }

    #brt_footer .\<lg\:flex-wrap {
        flex-wrap: wrap
    }

    #brt_footer .\<lg\:space-x-0>:not([hidden])~:not([hidden]) {
        --tw-space-x-reverse: 0;
        margin-right: calc(0px * var(--tw-space-x-reverse));
        margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse)))
    }
}
#brt_footer .visible {
    visibility: visible
}
#brt_footer .space-x-4>:not([hidden])~:not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1rem * var(--tw-space-x-reverse));
    margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)))
}
#brt_footer .flex-1 {
    flex: 1 1 0%
}
#brt_footer .mr-10 {
    margin-right: 2.5rem
}

  .swiper-pagination-bullet {
    background-color: white; 
  }

.brt-border-bl{
    border-color: #2267B4
}

.-z-1 {
    z-index: -1;
  }

  .origin-0 {
    transform-origin: 0%;
  }

  input:focus ~ label,
  input:not(:placeholder-shown) ~ label,
  textarea:focus ~ label,
  textarea:not(:placeholder-shown) ~ label,
  select:focus ~ label,
  select:not([value='']):valid ~ label {
    /* @apply transform; scale-75; -translate-y-6; */
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate))
      skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    --tw-scale-x: 0.75;
    --tw-scale-y: 0.75;
    --tw-translate-y: -1.5rem;
  }

  input:focus ~ label,
  select:focus ~ label {
    /* @apply text-black; left-0; */
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
    left: 0px;
  }
  
  .brt-swiper {
    overflow: visible;
    
  }

  .brt-swiper::-webkit-scrollbar {
        display: none; /* Safari и Chrome */
    }

    .card {
        position: relative;
        width: 300px;
        height: 300px;
        perspective: 1000px;
        transition: transform 0.5s ease;
        cursor: pointer; 
      }
      
      .front,
      .back {
        position: absolute;
        width: 100%;
        height: 100%;
        backface-visibility: hidden;
        transition: transform 0.5s;
      }
      
      .front {
        transform: rotateY(0);
      }
      
      .back {
        transform: rotateY(180deg);
      }
      
      .card.flipped .front {
        transform: rotateY(180deg);
      }
      
      .card.flipped .back {
        transform: rotateY(0);
      }

      .card.hovered {
        transform: translateY(-10px); /* Поднимаем div вверх на 10px при наведении */
      }